/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/content'
        icon='profile-circle'
        title='Content'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem to='/user' icon='user-square' title='User' fontIcon='bi-layers' />
    </>
  )
}

export {SidebarMenuMain}
