import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getProductListAPI} from './productapi'
import {IProduct} from './product.interface'

const getProductList = createAsyncThunk('getProductList', getProductListAPI)

const initialState = {
  productList: {},
  isLoading: false,
  error: {},
} as IProduct

const productList = createSlice({
  name: 'productList',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getProductList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProductList.fulfilled, (state, action) => {
      state.isLoading = false
      state.productList = action.payload
    })
    builder.addCase(getProductList.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export {getProductList, productList}
