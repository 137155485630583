import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import Dashboard from '../pages/dashboard/Dashboard'
import CreateModerator from '../pages/create-moderator/CreateModerator'
import UpdateModerator from '../pages/update-moderator/UpdateModerator'

const PrivateRoutes = () => {
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const Moderator = lazy(() => import('../pages/moderator/moderator'))
  const User = lazy(() => import('../pages/user/user'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<Dashboard />} />
        {/* to customize layout */}
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* Lazy Modules */}
        <Route
          path='/content'
          element={
            <SuspensedView>
              <Moderator />
            </SuspensedView>
          }
        />
        <Route
          path='/content/upsert-content'
          element={
            <SuspensedView>
              <CreateModerator />
            </SuspensedView>
          }
        />
        {/* <Route path='/content/update-content' element={<UpdateModerator />} /> */}
        <Route
          path='/user'
          element={
            <SuspensedView>
              <User />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
