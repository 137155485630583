import {fetchRequest} from '../../../../_metronic/helpers/fetch-request/fetchRequest'
import {API_CONFIG} from '../../../../_metronic/shared/Apiconfig'

export const getModeratorListAPI = async (params: any) => {
  try {
    const response = await fetchRequest(API_CONFIG.getModeratorList, {
      params,
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const deleteModeratorAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.deleteModerator}/admin/moderator/${id}/delete`,
      {
        method: 'DELETE',
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateStatusAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.updateStatusModerator}/admin/moderator/${id}/update-status`,
      {
        method: 'PATCH',
        body: {
          id,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateModeratorAPI = async ({firstName, lastName, email, id}) => {
  const response = await fetchRequest(
    `${API_CONFIG.updateModerator}/admin/moderator/${id}/update`,
    {
      method: 'PATCH',
      body: {
        firstName,
        lastName,
        email,
      },
    }
  )
  return response
}
