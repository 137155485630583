import {fetchRequest} from '../../../../helpers/fetch-request/fetchRequest'
import {API_CONFIG} from '../../../../shared/Apiconfig'

export const getProfileAPI = async () => {
  try {
    const response = await fetchRequest(API_CONFIG.getProfile)
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateProfileAPI = async ({firstName, lastName, email}) => {
  const response = await fetchRequest(API_CONFIG.updateProfile, {
    method: 'PATCH',
    body: {
      firstName,
      lastName,
      email,
    },
  })
  return response
}
