import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {IUser} from './user.interface'
import {getUserListAPI} from './userApi'

const getUserList = createAsyncThunk('getUserList', getUserListAPI)

const initialState = {
  userList: {},
  isLoading: false,
  error: {},
} as IUser

const userList = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.isLoading = false
      state.userList = action.payload
    })
    builder.addCase(getUserList.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export {getUserList, userList}
