import {useLayout} from '../layout/core'
import {ThemeModeComponent} from '../assets/ts/layout'

export const toAbsoluteUrl = (pathname: string) => {
  return process.env.PUBLIC_URL + pathname
}

export const useIllustrationsPath = (illustrationName: string): string => {
  const {config} = useLayout()

  const extension = illustrationName.substring(
    illustrationName.lastIndexOf('.'),
    illustrationName.length
  )
  const illustration =
    ThemeModeComponent.getMode() === 'dark'
      ? `${illustrationName.substring(0, illustrationName.lastIndexOf('.'))}-dark`
      : illustrationName.substring(0, illustrationName.lastIndexOf('.'))
  return toAbsoluteUrl(
    `/media/illustrations/${config.illustrations?.set}/${illustration}${extension}`
  )
}

export const debounce = (func: any, wait = 500) => {
  let h: NodeJS.Timeout
  return (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => func(...args), wait)
  }
}

// const errorMessage = {
//   1000: 'Internal server error',
//   1002: 'User is unauthorized',
//   1003: 'User account is banned',
//   1004: 'Validation failed',
//   1005: 'Bad request',
//   1006: 'Email is already taken',
//   1007: 'Toeken is expired',
//   1008: 'Invalid varification code',
//   1009: 'Not found',
// }

// export const handleErrorMessage = (errorCode: number) => {
//   toast.error(errorMessage[errorCode], {
//     position: 'top-right',
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: 'light',
//   })
// }
