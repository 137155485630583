import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getModeratorListAPI} from './moderatorapi'
import {IModerator} from './moderator.inteface'

const getModeratorList = createAsyncThunk('getModeratorList', getModeratorListAPI)

const initialState = {
  moderatorList: {},
  isLoading: false,
  error: {},
} as IModerator

const moderatorList = createSlice({
  name: 'moderatorList',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getModeratorList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getModeratorList.fulfilled, (state, action) => {
      state.isLoading = false
      state.moderatorList = action.payload
    })
    builder.addCase(getModeratorList.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export {getModeratorList, moderatorList}
