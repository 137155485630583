import {combineReducers} from '@reduxjs/toolkit'
import {profileData} from '../_metronic/partials/modals/profilemodal/store/profile.reducer'
import {moderatorList} from '../app/pages/moderator/store/moderator.reducer'
import {userList} from '../app/pages/user/store/user.reducer'
import {categoryList} from '../app/pages/category/store/category.reducer'
import {productList} from '../app/pages/product/store/product.reducer'
const rootReducer = combineReducers({
  profile: profileData.reducer,
  moderatorList: moderatorList.reducer,
  userList: userList.reducer,
  categoryList: categoryList.reducer,
  productList: productList.reducer,
})
export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
