import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {DataStates} from './profileinterface'
import {getProfileAPI} from './profileapi'

const fetchProfile = createAsyncThunk('fetchProfile', getProfileAPI)

const initialState = {
  profile: {},
  isLoading: false,
  error: {},
} as DataStates

const profileData = createSlice({
  name: 'profileData',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.isLoading = false
      state.profile = action.payload
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export {fetchProfile, profileData}
