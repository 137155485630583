const base_url = process.env.REACT_APP_BASE_URL

// const base_url = "https://preview.keenthemes.com/metronic8/laravel/api"

const API_CONFIG = {
  login: `${base_url}/admin/auth/login`,
  forgotPassword: `${base_url}/admin/auth/forgot-password`,
  resetPassword: `${base_url}/admin/auth/reset-password`,
  createModerator: `${base_url}/admin/moderator/create`,
  getProfile: `${base_url}/admin/account/me`,
  updateProfile: `${base_url}/admin/account/update`,
  changePassword: `${base_url}/admin/account/change-password`,
  getUser: `${base_url}`,
  getModeratorList: `${base_url}/admin/moderator/listing`,
  deleteModerator: `${base_url}`,
  updateModerator: `${base_url}`,
  getModeratorDetail: `${base_url}/admin/moderator/{id}/detail`,
  updateStatusModerator: `${base_url}`,
  updateStatusUser: `${base_url}`,
  getUserList: `${base_url}/admin/user/listing`,
  deleteUserData: `${base_url}`,
  updateUser: `${base_url}`,
  getCategoryList: `${base_url}/admin/category/listing`,
  deleteCategory: `${base_url}`,
  updateStatusCategory: `${base_url}`,
  updateCategory: `${base_url}`,
  createCategory: `${base_url}/admin/category/create`,
  createProperty: `${base_url}/admin/property/create`,
  getProductList: `${base_url}/admin/property/listing`,
  deleteProperty: `${base_url}`,
  uploadImageUrl: `${base_url}`,
  s3UploadUrl: `https://s3.us-west-1.amazonaws.com/dev-dt-dreams`,
  updateImageUrl: `${base_url}`,
  updateStatusProperty: `${base_url}`,
  updateproperty: `${base_url}`,
}

export {API_CONFIG}
