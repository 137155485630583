import React, {useEffect, useState} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import Button from '../../../_metronic/shared/components/form/button'
import 'react-toastify/dist/ReactToastify.css'
import './CreateModerator.scss'
import {toastMessage} from '../../../_metronic/helpers/toastmessage/toastMessage'
import * as Yup from 'yup'
import api from '../../utils/api'
import {useLocation, useNavigate} from 'react-router-dom'

interface ContentData {
  title: string
  description: string
}

const CreateModerator = () => {
  const [isloading, setIsloading] = useState<boolean>(false)
  const [contentData, setContentData] = useState<ContentData>() // State to store content data
  const [isLoadingData, setIsLoadingData] = useState(true) // State to track loading state

  const navigate = useNavigate()

  const location: any = useLocation()

  useEffect(() => {
    // Fetch content data from API and update the state
    const fetchContentData = async () => {
      try {
        const response = await api.get(`/content-detail?title=${location?.state?.title}`) // Replace with your actual API endpoint
        setContentData(response.data) // Assuming the response contains content data
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoadingData(false) // Data loading is complete
      }
    }

    fetchContentData()
  }, [])

  const initialValues = {
    title: contentData ? contentData.title : '',
    description: contentData ? contentData.description : '',
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  })

  const handleSubmit = async (values: any, {isSubmitting}: any) => {
    console.log(values, 'values')
    try {
      if (values) {
        setIsloading(true)
        const response = await api.post('/content', {
          title: values.title,
          description: values.description,
        })
        if (response.status === 201 || response.status === 200) {
          setIsloading(false)
          navigate('/content')
          toastMessage('success', `Content list updated successfully`)
        }
      }
    } catch (e: any) {
      console.log(e)
      setIsloading(false)
      isSubmitting(false)
    }
  }

  if (isLoadingData) {
    return <p>Loading...</p>
  }

  const handleTitleChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTitle = event.target.value;
    if (selectedTitle) {
      try {
        setIsLoadingData(true);
        const response = await api.get(`/content-detail?title=${selectedTitle}`);
        if (response.data) {
          // Existing content found, populate the form fields
          setContentData(response.data);
        } else {
          // No existing content, generate new content
          setContentData({
            title: selectedTitle,
            description: '', // Set initial description as needed
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingData(false);
      }
    }
  };

  return (
    <>
      <div className='bg-white'>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({isSubmitting}) => (
            <Form>
              <fieldset>
                <div className='row'>
                  <div className='col-12 col-md-6'>
                    <div className='form-group mb-4'>
                      <Field
                        as='select'
                        className='form-control'
                        name='title'
                        placeholder='Enter Title'
                        onChange={handleTitleChange}
                      >
                        <option value='' label='Select a title' />
                        <option value='About' label='About' />
                        <option value='About1' label='About1' />
                      </Field>
                      <ErrorMessage name='title' component='div' className='error-message' />
                    </div>
                  </div>
                  <div className='form-group mb-4'>
                    <Field
                      as='textarea'
                      className='form-control'
                      type='text'
                      name='description'
                      placeholder='Enter Description'
                    />
                    <ErrorMessage name='description' component='div' className='error-message' />
                  </div>
                </div>

                <div className='mb-3 mt-3 d-flex align-items-center justify-content-end mt-10'>
                  <Button
                    className='block m-b bg-dark text-white create-btn'
                    loading={isloading}
                    type='submit'
                    btnType='default'
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </fieldset>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

export default CreateModerator
