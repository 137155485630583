import {fetchRequest} from '../../../../_metronic/helpers/fetch-request/fetchRequest'
import {API_CONFIG} from '../../../../_metronic/shared/Apiconfig'

export const getProductListAPI = async (params: any) => {
  try {
    const response = await fetchRequest(API_CONFIG.getProductList, {
      params,
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const deleteProductAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.deleteProperty}/admin/property/${id}/delete`,
      {
        method: 'DELETE',
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateStatusAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.updateStatusProperty}/admin/property/${id}/update-status`,
      {
        method: 'PATCH',
        body: {
          id,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const UpdateProductAPI = async ({
  name,
  categoryId,
  price,
  id,
  description,
  details,
  legalInfo,
  externalLink,
  collectionId,
  tokenId,
  location,
  lat,
  lng,
  areaSize,
}) => {
  const response = await fetchRequest(`${API_CONFIG.updateproperty}/admin/property/${id}/update`, {
    method: 'PATCH',
    body: {
      name,
      categoryId,
      price,
      description,
      details,
      legalInfo,
      externalLink,
      collectionId,
      tokenId,
      location,
      lat,
      lng,
      areaSize,
    },
  })
  return response
}

export const uploadImageAPI = async ({id, mimeType}) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.uploadImageUrl}/admin/property/${id}/thumb-image/s3-upload-url`,
      {
        method: 'POST',
        body: {
          mimeType,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateImageAPI = async ({id, key}) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.updateImageUrl}/admin/property/${id}/thumb-image/update`,
      {
        method: 'POST',
        body: {
          key,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}
