/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'

const Dashboard: FC = () => {

  return (
    <>
      <div className='row row-cols-6'>
        <Link to='/content'>
          <div className=' bg-secondary text-center p-10 rounded counter_wrapper'>
            {/* <div className='mb-7'>
              {isLoading && <h1 className=' display-3 '>0</h1>}
              {!isLoading && (
                <h1 className=' display-3 '>{moderatorList?.data?.pagination?.itemCount}</h1>
              )}
            </div> */}
            <i></i>
            <h4>Content</h4>
          </div>
        </Link>
        <Link to='/user'>
          <div className=' bg-secondary text-center p-10 rounded counter_wrapper'>
            {/* <div className='mb-7'>
              {isLoading && <h1 className=' display-3 '>0</h1>}
              {!isLoading && (
                <h1 className=' display-3 '>{userList?.data?.pagination?.itemCount}</h1>
              )}
            </div> */}
            <i></i>
            <h4>User</h4>
          </div>
        </Link>
        {/* <Link to='/category'>
          <div className=' bg-secondary text-center p-10 rounded counter_wrapper'>
            <div className='mb-7'>
              {isLoading && <h1 className=' display-3 '>0</h1>}
              {!isLoading && (
                <h1 className=' display-3 '>{categoryList?.data?.pagination?.itemCount}</h1>
              )}
            </div>
            <i></i>
            <h4>Category</h4>
          </div>
        </Link>
        <Link to='/product'>
          <div className=' bg-secondary text-center p-10 rounded counter_wrapper'>
            <div className='mb-7'>
              {isLoading && <h1 className=' display-3 '>0</h1>}
              {!isLoading && (
                <h1 className=' display-3 '>{productList?.data?.pagination?.itemCount}</h1>
              )}
            </div>
            <i></i>
            <h4>Property</h4>
          </div>
        </Link> */}
        <div className=''></div>
        <div className=''></div>
        <div className=''></div>
        <div className=''></div>
        <div className=''></div>
      </div>
    </>
  )
}

export default Dashboard
