/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useAuth} from '../../../../app/modules/auth/core/Auth'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../redux/rootReducer'
import api from '../../../../app/utils/api'
import {Link, useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import {toastMessage} from '../../../helpers/toastmessage/toastMessage'

const HeaderUserMenu: FC = () => {
  const navigate = useNavigate()
  const {profile} = useSelector((state: RootState) => state.profile)

  const userLogOut = async () => {
    try {
      const response = await api.get('/logout')
      if (response.status === 200) {
        Cookies.remove('token')
        navigate('/auth')
      }
    } catch (error: any) {
      toastMessage('error', error)
    }
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src='/media/svg/avatars/profile_icon.svg' />
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to='' onClick={userLogOut} className='menu-link px-5'>
            Sign Out
          </Link>
        </div>
      </div>
    </>
  )
}

export {HeaderUserMenu}
