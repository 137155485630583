import {getAuth} from '../../../app/modules/auth'
import queryString from 'query-string'
interface FetchRequest {
  body?: {}
  method?: string
  headers?: {}
  params?: {}
  isUpload?: boolean
}

export const fetchRequest = async function (
  url: string,
  {body = {}, method = 'GET', headers = {}, params = {}, isUpload = false} = {} as FetchRequest
) {
  const bodyString = JSON.stringify(body)

  const user = getAuth()
  if (user && user.token) {
    if (!isUpload) {
      headers['Authorization'] = `Bearer ${user.token}`
    }
  }

  if (!isUpload) {
    headers['Content-Type'] = `application/json`
  } else {
    headers['Content-Type'] = `multipart/form-data`
  }

  if (params) {
    url = `${url}?${queryString.stringify(params)}`
  }
  try {
    const response = await fetch(url, {
      method,
      ...(!(Object.keys(body).length === 0) && {body: bodyString}),
      headers: {
        ...headers,
      },
    })
    // if (!response.ok) {
    //   throw new Error(response.status as any)
    // }
    return response.json()
  } catch (error) {
    console.log(error)
    if (error) {
      throw new Error((error || 'Bad response from server') as any)
    }
  }
}
