import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

// Create an Axios instance with default configuration
const api: AxiosInstance = axios.create({
  baseURL: 'https://api.texmex.ai/api/v1', // Set your API base URL here
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
api.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    const authRoute =
      config.url && (config.url.includes('login'));
    if (!authRoute) {
      const token = Cookies.get('token');
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return config as AxiosRequestConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log(response, 'respo')
    if (response.data.error) {
      return Promise.reject(response.data);
    } else {
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    window.location.href = '/auth';
    return Promise.reject(error.response.data);
  }
);

export default api;
//   (req: AxiosRequestConfig) => {
//     const authRoute =
//       req.url && (req.url.includes("login") || req.url.includes("signup"));
//     if (!authRoute) {
//       const token = Cookies.get("token");
//       if (token) {
//         req.headers["Authorization"] = `Bearer ${token}`;
//       }
//     }
//     return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// api.interceptors.response.use(
//   (res) => {
//     console.log("res", res);
//     if (res.data.error) {
//       return Promise.reject(res.data);
//     } else {
//       return Promise.resolve(res.data);
//     }
//   },
//   async (error: AxiosError) => {
//     const originalRequest = error.config;
//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       try {
//         // You can implement your token refreshing logic here
//         // const response = await refreshToken();
//         // if (response.status === 200) {
//         //   error.response.config.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
//         //   return api(originalRequest);
//         // }
//       } catch (error) {
//         window.location.href = "/auth/login";
//         return Promise.reject(error.response.data);
//       }
//     }
//     return Promise.reject(error.response?.data);
//   }
// );
