import {createContext, useContext, useState} from 'react'

type BreadcrumbsProps = {
  title: string
  isActive: boolean
  isSeparator: boolean
  path: string
}
type LayoutContextProps = {
  page: {
    title: string
    breadcrumbs: BreadcrumbsProps[]
  }
  setPage: (d: any) => void
  updateModerator: {
    firstName: string
    lastName: string
    email: string
    id: string
  }
  setUpdateModerator: (d: any) => void
  updateUser: {
    firstName: string
    lastName: string
    email: string
    id: string
  }
  setUpdateUser: (d: any) => void
  updateCategory: {
    name: string
    id: string
  }
  setUpdateCategory: (d: any) => void

  // UPDATE PRODUCT
  UpdateProduct: {
    name: string
    categoryId: {
      lable: string
      value: string
    }
    price: string
    id: string
    description: string
    details: string
    legalInfo: string
    externalLink: string
    collectionId: string
    tokenId: string
    location: string
    lat: string
    lng: string
    areaSize: string
    thumbImage: string
  }
  setUpdateProduct: (d: any) => void
}

const LayoutContext = createContext({
  // SET BREADCRUMBS
  setPage: () => {},
  page: {
    title: '',
    breadcrumbs: [],
  },

  // UPDATE MODERATOR
  updateModerator: {
    firstName: '',
    lastName: '',
    email: '',
    id: '',
  },
  setUpdateModerator: () => {},

  // UPDATE USER
  updateUser: {
    firstName: '',
    lastName: '',
    email: '',
    id: '',
  },
  setUpdateUser: () => {},

  // UPDATE CATEGORY
  updateCategory: {
    name: '',
    id: '',
  },
  setUpdateCategory: () => {},

  // UPDATE PRODUCT
  UpdateProduct: {
    name: '',
    categoryId: {
      lable: '',
      value: '',
    },
    price: '',
    id: '',
    description: '',
    details: '',
    legalInfo: '',
    externalLink: '',
    collectionId: '',
    tokenId: '',
    location: '',
    lat: '',
    lng: '',
    areaSize: '',
    thumbImage: '',
  },
  setUpdateProduct: () => {},
} as LayoutContextProps)

//   const LayoutContext = createContext
//   <LayoutContextProps>(initLayoutContextPropsState)

export const LayoutProvider = ({children}: any) => {
  const [page, setPage] = useState({
    title: '',
    breadcrumbs: {
      title: '',
      isActive: false,
      isSeparator: false,
      path: '',
    },
  })
  const [updateModerator, setUpdateModerator] = useState({})
  const [updateUser, setUpdateUser] = useState({})
  const [updateCategory, setUpdateCategory] = useState({})
  const [UpdateProduct, setUpdateProduct] = useState({})

  const value: any = {
    setPage,
    page,
    updateModerator,
    setUpdateModerator,
    updateUser,
    setUpdateUser,
    updateCategory,
    setUpdateCategory,
    UpdateProduct,
    setUpdateProduct,
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export const useLayoutContext = () => useContext(LayoutContext)
