import {fetchRequest} from '../../../../_metronic/helpers/fetch-request/fetchRequest'
import {API_CONFIG} from '../../../../_metronic/shared/Apiconfig'

export const getUserListAPI = async (params: string) => {
  try {
    const response = await fetchRequest(API_CONFIG.getUserList, {
      params,
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateStatusAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.updateStatusUser}/admin/user/${id}/update-status`,
      {
        method: 'PATCH',
        body: {
          id,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const deleteUserAPI = async (id: string) => {
  try {
    const response = await fetchRequest(`${API_CONFIG.deleteUserData}/admin/user/${id}/delete`, {
      method: 'DELETE',
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateUserAPI = async ({firstName, lastName, email, id}) => {
  const response = await fetchRequest(`${API_CONFIG.updateUser}/admin/user/${id}/update`, {
    method: 'PATCH',
    body: {
      firstName,
      lastName,
      email,
    },
  })
  return response
}

export const detailsUserAPI = async (id: string) => {
  try {
    const response = await fetchRequest(`${API_CONFIG.getUser}/admin/user/${id}/details`, {
      method: 'GET',
    })
    return response
  } catch (e) {
    console.log(e)
  }
}
