import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {getCategoryListAPI} from './categoryapi'
import {ICategory, ICategoryItems} from './category.interface'

const getCategoryList = createAsyncThunk('getCategoryList', getCategoryListAPI)

const initialState = {
  categoryList: {data: {items: [] as ICategoryItems[], pagination: {}}},
  isLoading: false,
  error: {},
} as ICategory

const categoryList = createSlice({
  name: 'categoryList',
  initialState,
  reducers: {
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.isLoading = false
      state.categoryList = action.payload
    })
    builder.addCase(getCategoryList.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export {getCategoryList, categoryList}
