import {fetchRequest} from '../../../../_metronic/helpers/fetch-request/fetchRequest'
import {API_CONFIG} from '../../../../_metronic/shared/Apiconfig'

export const getCategoryListAPI = async (params: string) => {
  try {
    const response = await fetchRequest(API_CONFIG.getCategoryList, {
      params,
    })
    return response
  } catch (e) {
    console.log(e)
  }
}

export const deleteCategoryAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.deleteCategory}/admin/category/${id}/delete`,
      {
        method: 'DELETE',
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateStatusAPI = async (id: string) => {
  try {
    const response = await fetchRequest(
      `${API_CONFIG.updateStatusCategory}/admin/category/${id}/update-status`,
      {
        method: 'PATCH',
        body: {
          id,
        },
      }
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const updateCategoryAPI = async ({name, id}) => {
  const response = await fetchRequest(`${API_CONFIG.updateCategory}/admin/category/${id}/update`, {
    method: 'PATCH',
    body: {
      name,
    },
  })
  return response
}
